import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
// Material UI
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import useSnackbar from '../../hooks/useSnackbar';

// Components
import buildUrl from '../../buildUrl';
import useApi from '../../hooks/useApi';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import LoadingBox from '../accountDetails/components/LoadingBox';
import ExportAuditDialog from './ExportAuditDialog';
import DownloadExportedFileDialog from '../../shared/DownloadExportedFileDialog';
import DatePicker from '../../DatePicker';

const fields = [
    { property: 'dateTime', type: 'date', label: 'Date/Time' },
    { property: 'customerId', type: 'number', label: 'CID', filter: true },
    { property: 'emailAddress', type: 'text', label: 'Email Address', filter: true },
    { property: 'section', type: 'text', label: 'Section' },
    { property: 'action', type: 'text', label: 'Action', filter: true },
    { property: 'item', type: 'text', label: 'Item', filter: true },
    { property: 'ipAddress', type: 'text', label: 'IP Address', filter: true }
];

const rowsPerPageOptions = [10, 20, 50];
const cellStyles = { whiteSpace: 'nowrap', textOverflow: 'ellipsis' };

// todo get from server

const sections = [
    { id: 1, name: 'Account' },
    { id: 2, name: 'Create \u0026 Send' },
    { id: 3, name: 'Contacts / Groups' },
    { id: 4, name: 'Contacts / Segments' },
    { id: 5, name: 'Automation' },
    { id: 6, name: 'Reports' },
    { id: 10, name: 'Subscribers / Groups' },
    { id: 11, name: 'Subscribers / Segments' },
    { id: 12, name: 'Track' },
    { id: 13, name: 'Contacts' }
];

const AuditingTable = ({ cid = null, rowsOption = null, useLoadingBox = false, handleAlert = null }) => {

    const originalState = {
        searchField: 'emailAddress',
        searchValue: '',
        cidSearchField: 'customerId',
        cidSearchValue: cid ?? '',
        sectionId: '',
        fromDate: null,
        toDate: null
    };

    const { handleGet } = useApi();
    const [isLoading, setIsLoading] = useState(true);
    const [count, setCount] = useState(0);
    const [actions, setActions] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[rowsOption ?? 1]);
    const [dialog, setDialog] = useState(null);
    const { showSnackbar } = useSnackbar();

    const [searchField, setSearchField] = useState(originalState.searchField);
    const [tempValue, setTempValue] = useState('');
    const [searchValue, setSearchValue] = useState(originalState.searchValue);
    const [sectionId, setSectionId] = useState(originalState.sectionId);
    const [fromDate, setFromDate] = useState(originalState.fromDate);
    const [toDate, setToDate] = useState(originalState.toDate);

    const [tempCidValue, setTempCidValue] = useState(cid ?? '');

    const cidSearchField = originalState.cidSearchField;

    const [cidSearchValue, setCidSearchValue] = useState(originalState.cidSearchValue);

    const [orderBy, setOrderBy] = useState('dateTime');
    const [isDescending, setIsDescending] = useState(true);

    const handleFetchCount = async () => {
        const url = buildUrl('superadmin/audit-actions/count', {
            ...(searchValue && { searchField, searchValue }),
            sectionId,
            ...(fromDate && { startDate: format(fromDate, 'yyyy-MM-dd') }),
            ...(toDate && { endDate: format(toDate, 'yyyy-MM-dd') }),
            ...(cidSearchValue && { customerId: cidSearchValue })
        });

        const response = await handleGet(url);

        if (response.ok) {
            const data = await response.json();
            setCount(data);
        }
        else {
            setCount(0);
            showSnackbar('No data found', 'error');
        }
    };

    const handleFetchActions = async () => {
        setIsLoading(true);
        const url = buildUrl('superadmin/audit-actions', {
            skip: page * rowsPerPage,
            take: rowsPerPage,
            orderBy,
            isDescending,
            ...(searchValue && { searchField, searchValue }),
            sectionId,
            ...(fromDate && { startDate: format(fromDate, 'yyyy-MM-dd') }),
            ...(toDate && { endDate: format(toDate, 'yyyy-MM-dd') }),
            ...(cidSearchValue && { customerId: cidSearchValue })
        });

        const response = await handleGet(url);

        if (response.ok) {
            setIsLoading(false);
            const data = await response.json();
            setActions(data);
            setIsLoading(false);
        }
        else {
            setActions([]);
            setIsLoading(false);
            showSnackbar('No data found', 'error');
        }
    };

    const handleSortActions = field => {
        if (orderBy === field) {
            setIsDescending(!isDescending);
        }
        else {
            setIsDescending(field === 'dateTime');
        }

        setOrderBy(field);
    };

    const handleExportAudit = async () => {
        setDialog(null);
        setIsLoading(true);

        const url = buildUrl('superadmin/audit-actions/export', {
            customerId: cidSearchValue,
            ...(searchValue && { searchField, searchValue }),
            sectionId,
            ...(fromDate && { startDate: format(fromDate, 'yyyy-MM-dd') }),
            ...(toDate && { endDate: format(toDate, 'yyyy-MM-dd') })
        });

        const response = await handleGet(url);
        const data = await response.json();

        setIsLoading(false);

        setDialog(
            <DownloadExportedFileDialog
                downloadUrl={data.downloadPath}
                onClose={() => setDialog(null)}
                title="Audit Report"
            />
        );
    };

    const handleOpenExportDialog = () => {
        setDialog(
            <ExportAuditDialog
                customerId={cidSearchValue}
                onClose={() => setDialog(null)}
                onComplete={handleExportAudit}
            />
        );
    };

    // Side effects

    useEffect(() => {
        handleFetchCount();

        if (
            searchField !== originalState.searchField ||
            searchValue !== originalState.searchValue ||
            cidSearchField !== originalState.cidSearchField ||
            cidSearchValue !== originalState.cidSearchValue ||
            sectionId !== originalState.sectionId ||
            fromDate !== originalState.fromDate ||
            toDate !== originalState.toDate
        ) {
            handleAlert && handleAlert(true);
        }
        else {
            handleAlert && handleAlert(false);
        }
    }, [searchField, searchValue, cidSearchField, cidSearchValue, sectionId, fromDate, toDate]);

    useEffect(() => {
        setPage(0);
    }, [searchField, searchValue, cidSearchField, cidSearchValue, sectionId, fromDate, toDate, orderBy, isDescending, rowsPerPage]);

    useEffect(() => {
        handleFetchActions();

        if (
            searchField !== originalState.searchField ||
            searchValue !== originalState.searchValue ||
            cidSearchField !== originalState.cidSearchField ||
            cidSearchValue !== originalState.cidSearchValue ||
            sectionId !== originalState.sectionId ||
            fromDate !== originalState.fromDate ||
            toDate !== originalState.toDate
        ) {
            handleAlert && handleAlert(true);
        }
        else {
            handleAlert && handleAlert(false);
        }
    }, [searchField, searchValue, cidSearchField, cidSearchValue, sectionId, fromDate, toDate, orderBy, isDescending, rowsPerPage, page]);

    return (
        <>
            <Toolbar
                disableGutters
                sx={{ px: 2 }}
            >
                <Box style={{ flexGrow: 1 }}>

                    <Grid container alignItems="center" spacing={1} flexWrap="nowrap">
                        <Grid item>
                            <FormControl margin="none">
                                <Select
                                    value={sectionId}
                                    onChange={(e) => {
                                        setSectionId(e.target.value);
                                    }}
                                    size="small"
                                    displayEmpty
                                >
                                    <MenuItem value="">All Sections</MenuItem>
                                    {sections.map(({ id, name }) => (
                                        <MenuItem key={id} value={id}>{name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <DatePicker value={fromDate} onChange={setFromDate} label="From Date"
                            />
                        </Grid>
                        <Grid item>
                            <DatePicker value={toDate} onChange={setToDate} label="To Date" />
                        </Grid>
                        <Grid item>
                            <form onSubmit={e => {
                                e.preventDefault();
                                setCidSearchValue(tempCidValue);
                            }}>
                                <FormControl margin="none">
                                    <TextField
                                        value={tempCidValue}
                                        onChange={(e) => {
                                            setTempCidValue(e.target.value);
                                        }}
                                        margin="none"
                                        size="small"
                                        placeholder="Search CID..."
                                        autoComplete="off"
                                        disabled={cid ?? false}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        disableRipple
                                                        edge="end"
                                                        disabled={tempCidValue === cidSearchValue}
                                                        type="submit"
                                                    >
                                                        <SearchIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </FormControl>
                            </form>
                        </Grid>

                        <Grid item>
                            <FormControl margin="none">
                                <Select
                                    value={searchField}
                                    onChange={(e) => {
                                        setSearchField(e.target.value);
                                        setTempValue('');
                                        setSearchValue('');
                                    }}
                                    size="small"
                                >
                                    {fields
                                        .filter(({ filter, property }) => Boolean(filter) && property !== 'customerId')
                                        .map(({ property, label }) => (
                                            <MenuItem key={property} value={property}>{label}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <form onSubmit={e => {
                                e.preventDefault();
                                setSearchValue(tempValue);
                            }}>
                                <FormControl margin="none">
                                    <TextField
                                        value={tempValue}
                                        onChange={(e) => {
                                            setTempValue(e.target.value);
                                        }}
                                        margin="none"
                                        size="small"
                                        placeholder="Search..."
                                        autoComplete="off"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        disableRipple
                                                        edge="end"
                                                        disabled={tempValue === searchValue}
                                                        type="submit"
                                                    >
                                                        <SearchIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </FormControl>
                            </form>
                        </Grid>

                        <Grid item>
                            <Button onClick={handleOpenExportDialog} disabled={!cidSearchValue || actions.length === 0}>Export</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Toolbar>
            <Divider />
            {isLoading ? (useLoadingBox ? <LoadingBox /> : <LoadingOverlay />) : (
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                {fields.map(({ property, label, align }) => (
                                    <TableCell
                                        key={property}
                                        align={align}
                                        component="th"
                                        sx={cellStyles}
                                    >
                                        {property === 'dateTime' || property === 'customerId' ? (
                                            <TableSortLabel
                                                active={orderBy === property}
                                                direction={isDescending ? 'desc' : 'asc'}
                                                onClick={() => handleSortActions(property)}
                                            >
                                                {label}
                                            </TableSortLabel>
                                        ) : (
                                            label
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {actions.map(action => {
                                const cells = fields.map(({ property, type, align }) => type === 'date' ? (
                                    <TableCell
                                        key={property}
                                        align={align}
                                        sx={cellStyles}
                                    >
                                        {action[property] ? format((new Date(action[property])), 'dd/MM/yyyy HH:mm:ss') : '-'}
                                    </TableCell>
                                ) : (
                                    <TableCell
                                        key={property}
                                        align={align}
                                        sx={cellStyles}
                                    >
                                        {action[property] || '-'}
                                    </TableCell>
                                ));

                                return (
                                    <TableRow key={action.id}>
                                        {cells}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(e, newPage) => setPage(newPage)}
                onRowsPerPageChange={e => setRowsPerPage(e.target.value)}
            />
            {dialog}
        </>
    );
};

export default AuditingTable;