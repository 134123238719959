/* eslint-disable react/no-danger */
import React, { useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useAccount from '../hooks/useAccount';
import { addDays, addYears, format } from 'date-fns';

const StartItem = ({ imgSrc, title, href, buttonText }) => {
    return (
        <Card>
            <CardActionArea href={href}>
                <CardContent>
                    <Box component="img" src={imgSrc} sx={{ marginBottom: 1 }} />
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography
                            component="p"
                            variant="body1"
                            gutterBottom
                        >
                            {title}
                        </Typography>
                    </Box>
                </CardContent>
            </CardActionArea>
            <CardActions>
                <Button
                    fullWidth
                    href={href}
                >
                    {buttonText}
                </Button>
            </CardActions>
        </Card>
    );
};

const Iframe = props => {
    const ref = useRef(null);
    const [width, setWidth] = useState(null);
    const [height, setHeight] = useState(null);
    const ratio = 0.5625;

    const handleResizeIframe = () => {
        if (!ref.current) {
            return;
        }

        setWidth(ref.current.offsetWidth);
        setHeight(ref.current.offsetWidth * ratio);
    };

    useEffect(() => {
        handleResizeIframe();
        window.addEventListener('resize', handleResizeIframe);
        return () => window.removeEventListener('resize', handleResizeIframe);
    }, []);

    return (
        <div ref={ref}>
            <iframe
                {...props}
                width={width}
                height={height}
                style={{ display: 'block' }}
            />
        </div>
    );
};

const Start = () => {
    const { account, hasCustomSmtpConfiguration, smtpModified } = useAccount();
    const [alertMessages, setAlertMessages] = useState<{ severity: 'error' | 'warning' | 'info'; message: string }[]>([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');

    useEffect(() => {
        let alerts = [];

        if (hasCustomSmtpConfiguration && smtpModified) {
            if (new Date() > addYears(new Date(smtpModified), 1)) {
                alerts.push({
                    severity: 'warning',
                    message: `Your Trusted Delivery SMTP password was set on ${format(new Date(smtpModified), 'dd/MM/yyyy')}. It may have expired.`
                });
            }
            else if (addDays(new Date(), 7) > addYears(new Date(smtpModified), 1)) {
                alerts.push({
                    severity: 'warning',
                    message: `Your Trusted Delivery SMTP password was set on ${format(new Date(smtpModified), 'dd/MM/yyyy')}. It may be due to expire soon.`
                });
            }
        }

        if (account.licence.lifetimeInMonths === 12) {
            const renewalDate = new Date(account.maxRenewalDate);
            const today = new Date();
            const timeDifference = renewalDate.getTime() - today.getTime();
            const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
            const lastDismissed = parseInt(localStorage.getItem(`dialogDismissedExpiry_${account.id}`) || '0');
            const shouldOpenDialog = new Date().getTime() > lastDismissed;

            if (daysLeft <= 1) {
                const message = 'Your current NewZapp licence is due to expire tomorrow. We have not yet received payment for your renewal. Without payment your account will not be accessible after today.';

                alerts.push({
                    severity: 'error',
                    message
                });

                setOpenDialog(shouldOpenDialog);
                setDialogMessage(message);
            }
            else if (daysLeft <= 7) {
                const message = `Your current NewZapp licence is due to expire on ${renewalDate.toDateString()}. We have not yet received payment for your renewal. Please ensure your renewal payment is received by your renewal date or you will not be able to access the platform.`;

                alerts.push({
                    severity: 'error',
                    message
                });

                setOpenDialog(shouldOpenDialog);
                setDialogMessage(message);
            }
            else if (daysLeft <= 14) {
                alerts.push({
                    severity: 'warning',
                    message: `Your current NewZapp licence is due to expire on ${renewalDate.toDateString()}. We have not yet received payment for your renewal. Please ensure your renewal payment is received by your renewal date or you will not be able to access the platform.`
                });
            }
            else if (daysLeft <= 30) {
                alerts.push({
                    severity: 'warning',
                    message: `Your current NewZapp licence is due to expire on ${renewalDate.toDateString()}, please ensure your renewal payment is received by this date.`
                });
            }
            else if (daysLeft <= 60) {
                alerts.push({
                    severity: 'info',
                    message: `Your current NewZapp licence is due to expire on ${renewalDate.toDateString()}, your account manager will contact you to discuss your requirements.`
                });
            }

            setAlertMessages(alerts);
        }
    }, [account]);

    const handleDialogClose = () => {
        setOpenDialog(false);
        const expiryDate = new Date();
        expiryDate.setHours(23, 59, 59, 999);

        localStorage.setItem(`dialogDismissedExpiry_${account.id}`, expiryDate.getTime().toString());
    };

    return (
        <Box sx={{ margin: 3 }}>
            {alertMessages.length > 0 && alertMessages.map(({ message, severity }, i) => (
                <Alert key={i} sx={{ mb: 2 }} variant="filled" severity={severity}>
                    {message}
                </Alert>
            ))}
            <Grid container spacing={3}>
                <Grid xs={7} container item spacing={3}>
                    <Grid item xs={6}>
                        <StartItem
                            imgSrc="/images/get-started.svg"
                            title="Create &amp; Send with our easy to use editor"
                            href="#/editor"
                            buttonText="Create &amp; Send"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <StartItem
                            imgSrc="/images/view-reports.svg"
                            title="Track your campaign progress and engagement"
                            href="#/reports"
                            buttonText="View Reports"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <StartItem
                            imgSrc="/images/import-contacts.svg"
                            title="Manage contacts, groups and segments"
                            href="#/contacts"
                            buttonText="Contacts"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <StartItem
                            imgSrc="/images/audit-trail.svg"
                            title="Manage accountability with multi-user audit trail"
                            href="#/account/auditing"
                            buttonText="Audit Trail"
                        />
                    </Grid>
                </Grid>
                <Grid item xs={5}>
                    <Card>
                        <Iframe
                            src="https://www.youtube.com/embed/videoseries?list=PLHN7sO5bejWtQFpBs8_G8fnc09piRlGgf"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    </Card>
                </Grid>
            </Grid>
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Licence Expiry Warning</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {dialogMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Start;