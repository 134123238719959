import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

const ExportAuditDialog = ({ customerId, onClose, onComplete }) => {

    const handleSubmit = e => {
        e.preventDefault();
        onComplete();
    };

    return (
        <Dialog onClose={onClose} open={true}>
            <form onSubmit={handleSubmit}>
                <DialogTitle>Export Audit Report</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Stack sx={{ width: '100%' }} spacing={2}>
                            <div>
                        Export Audit Report for Customer ID: <strong>{customerId}</strong>.
                            </div>
                            <Alert severity="info">Please note: This export is limited to 10,000 records.</Alert>
                        </Stack>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button type="submit">Export Audit Report</Button>
                    <Button variant="outlined" onClick={onClose}>Cancel</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default ExportAuditDialog;