import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';

const SubAccounts = ({ account, hasManageUsersPermission, handleExportUserListData }) => {
    const [expanded, setExpanded] = useState(false);
    const location = useLocation();

    const handleToggle = () => {
        setExpanded(!expanded);
    };

    return (
        <>
            <Toolbar
                variant="dense"
                disableGutters
                sx={{ px: 2 }}
            >
                <div style={{ flexGrow: 1 }}>
                    <Typography variant="subtitle2">
                        Sub Account Details
                    </Typography>
                </div>
                <IconButton onClick={handleToggle}>
                    <ExpandMoreIcon />
                </IconButton>
            </Toolbar>
            <Divider />
            <Collapse in={expanded}>
                <Box>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>CID</TableCell>
                                    <TableCell align="left">Customer Name</TableCell>
                                    <TableCell align="left">Account Type</TableCell>
                                    <TableCell align="right">Users</TableCell>
                                    <TableCell align="right">
                                        {hasManageUsersPermission && (
                                            <Button variant="contained" onClick={handleExportUserListData}>Export Users</Button>
                                        )}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {account.subAccountList.map((subAccount) => (
                                    <TableRow
                                        key={subAccount.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {subAccount.id}
                                        </TableCell>
                                        <TableCell align="left">{subAccount.customerName}</TableCell>
                                        <TableCell align="left">{subAccount.isInternalComms ? 'Internal' : 'External'}</TableCell>
                                        <TableCell align="right">{subAccount.users.length} / {subAccount.userLimit}</TableCell>
                                        <TableCell align="right">
                                            {location.pathname.includes('/superadmin') && (
                                                <Link href={`/#/superadmin/accounts/${subAccount.id}`}>View Account</Link>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Collapse>
        </>
    );
};

export default SubAccounts;