import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import useAccount from '../../hooks/useAccount';
import useApi from '../../hooks/useApi';
import useSnackbar from '../../hooks/useSnackbar';
import { format } from 'date-fns';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import EditProfileDialog from '../../header/dialogs/EditProfileDialog';
import Button from '@mui/material/Button';
import AlertBar from '../../alertBar/AlertBar';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import Grid from '@mui/material/Grid';
import { formatDistanceToNow } from 'date-fns';
import SubAccounts from '../../superAdmin/accountDetails/components/SubAccounts';
import DownloadExportedFileDialog from '../../shared/DownloadExportedFileDialog';

const GeneralSettings = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [account, setAccount] = useState();
    const { handleGet, handlePut } = useApi();
    const { user, handleRefresh } = useAccount();
    const { showSnackbar } = useSnackbar();
    const [showEditProfileDialog, setShowEditProfileDialog] = useState(false);
    const [name, setName] = useState(user.name);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [dialog, setDialog] = useState(null);

    const hasManageUsersPermission = user.permissions.includes('Manage Users');

    const handleFetchAccount = async () => {
        const response = await handleGet('account');

        if (!response.ok) {
            setIsLoading(false);
            return;
        }

        const data = await response.json();

        setAccount(data);
        setIsLoading(false);
    };

    const onSubmit = async e => {
        e.preventDefault();

        setIsSubmitting(true);

        const params = {
            name
        };

        const response = await handlePut('user', params);

        if (!response.ok) {
            showSnackbar('Error occurred while attempting to update your details');
            setIsLoading(false);
            return;
        }

        await handleRefresh();
        setIsLoading(false);
        setIsSubmitting(false);
    };

    const handleExportUserListData = async () => {
        setDialog(null);
        setIsLoading(true);

        const response = await handleGet('account/sub-account/users/export');

        if (!response.ok) {
            setIsLoading(false);
            showSnackbar('Sub Account Users List Export cannot be used at this time', 'error');
            return;
        }

        const data = await response.json();

        setIsLoading(false);

        setDialog(
            <DownloadExportedFileDialog
                downloadUrl={data.downloadPath}
                onClose={() => setDialog(null)}
                title="Sub Accounts Users"
            />
        );
    };

    useEffect(() => {
        handleFetchAccount();
    }, []);

    useEffect(() => {
        setHasUnsavedChanges(name !== user.name);
    }, [account, name, user]);

    if (isLoading) {
        return (
            <LoadingOverlay />
        );
    }

    const LastLogin = ({ lastLoginDate }) => {
        const lastLogin = new Date(lastLoginDate);

        const timeAgo = formatDistanceToNow(lastLogin, { addSuffix: true, includeSeconds: true });

        return (
            <Typography variant="body2" color="textSecondary">
                Last login: {timeAgo}
            </Typography>
        );
    };

    return (
        <>
            <AlertBar
                shown={isLoading || hasUnsavedChanges}
                action={(
                    <Button
                        variant="outlined"
                        size="small"
                        disabled={isLoading}
                        onClick={onSubmit}
                    >
                        Save Changes
                    </Button>
                )}
                positionTop={128}
                mb={2}
            >
                {isLoading ? 'Updating...' : 'You have unsaved changes.'}
            </AlertBar>
            <Paper sx={{ mt: 2 }}>
                <Grid container spacing={2}>
                    <Grid item md={7}>
                        <Box sx={{ p: 3 }}>
                            <Box sx={{ display: 'flex', mt: 2 }}>
                                <Box sx={{ mr: 2 }}>
                                    <Box
                                        sx={{
                                            perspective: '100px',
                                            width: 56,
                                            height: 56
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                position: 'relative',
                                                width: '100%',
                                                height: '100%',
                                                transformStyle: 'preserve-3d',
                                                transition: 'transform 0.6s',
                                                '&:hover': {
                                                    transform: 'rotateY(180deg)'
                                                }
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    width: '100%',
                                                    height: '100%',
                                                    backfaceVisibility: 'hidden'
                                                }}
                                            >
                                                <IconButton
                                                    sx={{
                                                        width: 56,
                                                        height: 56,
                                                        padding: 0
                                                    }}
                                                >
                                                    <Avatar
                                                        sx={{
                                                            width: 56,
                                                            height: 56
                                                        }}
                                                        alt={user.name}
                                                        src={user.avatarURL}
                                                    />
                                                </IconButton>
                                            </Box>
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    width: '100%',
                                                    height: '100%',
                                                    backfaceVisibility: 'hidden',
                                                    transform: 'rotateY(180deg)',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                                    borderRadius: '50%'
                                                }}
                                            >
                                                <IconButton
                                                    sx={{
                                                        width: 56,
                                                        height: 56
                                                    }}
                                                    onClick={() => setShowEditProfileDialog(true)}
                                                >
                                                    <CameraAltOutlinedIcon />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box>
                                    <Typography variant="h5">{user.name}</Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        <LastLogin lastLoginDate={user.lastLoggedIn} />
                                    </Typography>
                                    <Chip sx={{ mt: 1 }} label={user.provider ? user.provider : 'Username & Password'} variant="outlined" size="small" />
                                </Box>
                            </Box>

                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Name"
                                        value={name}
                                        onChange={(event) => setName(event.target.value)}
                                        variant="outlined"
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Email"
                                        defaultValue={user.emailAddress}
                                        variant="outlined"
                                        margin="normal"
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>

                    <Grid item md={5}>
                        <Box display="flex" justifyContent="center" sx={{ p: 4 }}>
                            <Divider orientation="vertical" flexItem />
                            <Box sx={{ p: 4, alignContent: 'center' }}>
                                <Stack spacing={2}>
                                    <Typography variant="h6">{account.licence.productName}</Typography>
                                    <Typography variant="button">Organisation ID: {account.resellerId === 0 || account.resellerId === null ? 'None' : account.resellerId}</Typography>
                                    <Typography variant="button">Customer ID: {account.customerId}</Typography>
                                    <Typography variant="button">Licence Start: {account.licenceStartDate ? format(new Date(account.licenceStartDate), 'dd/MM/yyyy') : '-'}</Typography>
                                    <Typography variant="button">Licence Expiry: {account.licenceEndDate ? format(new Date(account.licenceEndDate), 'dd/MM/yyyy') : '-'}</Typography>
                                </Stack>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
            <Paper sx={{ mt: 2 }}>
                {(account.subAccountList && account.subAccountList.length > 0) && (
                    <SubAccounts
                        account={account}
                        hasManageUsersPermission={hasManageUsersPermission}
                        handleExportUserListData={handleExportUserListData}
                    />
                )}
            </Paper>
            {dialog}
            {showEditProfileDialog && (
                <EditProfileDialog
                    displayName={user.name}
                    avatarURL={user.avatarURL}
                    onClose={() => setShowEditProfileDialog(false)}
                    onRefresh={() => handleRefresh()}
                />
            )}
            {(isSubmitting) && (
                <LoadingOverlay />
            )}
        </>
    );
};

export default GeneralSettings;