import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import enLocale from 'date-fns/locale/en-GB';
import useInputDate from '../../hooks/useInputDate';
import { Typography } from '@mui/material';
import isValid from 'date-fns/esm/fp/isValid/index.js';
import useSnackbar from '../../hooks/useSnackbar';
import { isPast } from 'date-fns';

const UnsubscribeSuppressContactsDialog = ({ onClose, onConfirm, titleText, bodyText, operation }) => {
    const suppressUntilDateInput = useInputDate(null);
    const { showSnackbar } = useSnackbar();
    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        !isValid(suppressUntilDateInput.value) && operation === 'Suppress' ? setIsDisabled(true) : setIsDisabled(false);
    });

    const handleConfirm = e => {
        e.preventDefault();
        isPast(suppressUntilDateInput.value) ? showSnackbar('Invalid date in the past', 'error') : onConfirm(suppressUntilDateInput.value);
        onClose();
    };

    return (
        <Dialog onClose={onClose} open={true}>
            <DialogTitle>{titleText} Contacts</DialogTitle>
            <DialogContent>
                <Alert severity="warning">
                    {bodyText}
                </Alert>
                {titleText === 'Suppress' &&
                    (
                        <>
                            <Typography sx={{ mt: 2 }}>
                                These contacts will not receive campaigns until the beginning of the day you specify. You can unsuppress any or all of these contacts at any time.
                            </Typography>
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
                                <DatePicker
                                    mask="__/__/____"
                                    inputFormat="dd/MM/yyyy"
                                    renderInput={(props) => <TextField fullWidth {...props} />}
                                    label="Suppress Until Date"
                                    value={suppressUntilDateInput.value}
                                    onChange={suppressUntilDateInput.onChange}
                                    disablePast
                                />
                            </LocalizationProvider>
                        </>
                    )
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirm} disabled={isDisabled}>Confirm {titleText}</Button>
                <Button variant="outlined" onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default UnsubscribeSuppressContactsDialog;