import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Divider from '@mui/material/Divider';
import useAccount from '../hooks/useAccount';
import ErrorDialog from './ErrorDialog';
import AccountPicker from './components/AccountPicker';

const InvalidLicenceDialog = () => {
    const { accounts, account, handleChangeAccount } = useAccount();
    const { logout } = useAuth0();
    const returnTo = 'https://www.newzapp.co.uk/email-marketing/logout-thank-you.php';
    const filteredAccounts = Boolean(account) ? accounts.filter(({ id }) => id !== account.id) : accounts;

    if (filteredAccounts.length > 0) {
        return (
            <Dialog open={true}>
                <DialogTitle>Invalid Licence</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Your selected account does not have a valid licence. Please contact support or select another account you are invited to.
                    </DialogContentText>
                    <AccountPicker
                        accounts={filteredAccounts}
                        handleChangeAccount={handleChangeAccount}
                    />
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button variant="contained" onClick={() => logout({ returnTo })}>Sign Out</Button>
                </DialogActions>
            </Dialog>
        );
    }

    // todo create account process could come in here

    return (
        <ErrorDialog
            message="Your account does not have a valid licence."
        />
    );
};

export default InvalidLicenceDialog;